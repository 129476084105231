var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-body-1",style:({
    width: '48px',
    textAlign: _vm.header.align ? _vm.header.align : 'center',
    whiteSpace: _vm.noWrap ? 'nowrap' : '',
    paddingLeft: _vm.additionalPadding ? _vm.additionalPadding : undefined,
    paddingRight: _vm.additionalPadding ? _vm.additionalPadding : undefined,
  }),on:{"click":function($event){$event.preventDefault();return _vm.$emit('clicked', _vm.item)}}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"shrink":""}},'v-layout',attrs,false),on),[_c('v-icon',{class:_vm.clickable && 'cursor',attrs:{"size":"16px","color":_vm.color}},[_vm._v(_vm._s(_vm.value))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }