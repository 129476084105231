<template>
  <td
    class="text-body-1"
    :style="{
      width: '48px',
      textAlign: header.align ? header.align : 'center',
      whiteSpace: noWrap ? 'nowrap' : '',
      paddingLeft: additionalPadding ? additionalPadding : undefined,
      paddingRight: additionalPadding ? additionalPadding : undefined,
    }"
    @click.prevent="$emit('clicked', item)"
  >
    <v-tooltip top :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-layout shrink v-on="on" v-bind="attrs">
          <v-icon size="16px" :class="clickable && 'cursor'" :color="color">{{
            value
          }}</v-icon>
        </v-layout>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {},
    noWrap: {
      type: Boolean,
      default: true,
    },
    additionalPadding: {
      type: String,
      default: "0px",
    },
    icon: {
      default: undefined,
    },
    color: {
      default: "red",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
